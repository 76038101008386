import React from 'react';
import {css} from '@emotion/core';

import {bp} from '../components/layout';
import logo from '../images/logo-alpha-2.png';
import logoVenture from '../images/logo-venture.png';
// import OverlayGreen from '../components/overlay-green';


export default ({image}) => (
  <>
    {/* <OverlayGreen css={css`
      min-height: 960px;
      ${bp.xs} {
        min-height: 600px;
      }
    `}/> */}
    <div
      css={css`
        min-height: 960px;
        padding-top: 40px;
        margin-top: -1px;
        z-index: 0;
        background-size: cover;
        background-position: center;
        font-weight: 600;
        color: white;
        ${bp.xs} {
          min-height: 600px;
        }
      `}
      style={{
        backgroundImage: `url(/${image})`
      }}
    >

      {/* desktop */}
      <div className="d-none d-sm-block">
        <div css={css`
          position: relative;
          max-width: 800px;
          margin: 0 auto;
          ${bp.sm} {
            max-width: 1050px;
          }
        `}>
          <img
            src={logoVenture}
            alt="coton-logo"
            css={css`
              position: absolute;
              left: 0px;
              top: 35px;
              max-width: 200px;
              ${bp.xs} {
                top: 20px;
                max-width: 200px;
              }
              ${bp.sm} {
                top: 15px;
                max-width: 330px;
              }
              ${bp.md} {
                top: 10px;
                max-width: 440px;
              }
              mix-blend-mode: hard-light;
              // background-color: #fff;
            `}
          />
          <img
            src={logo}
            alt="coton-logo"
            css={css`
              position: absolute;
              left: 0px;
              top: 45px;
              max-width: 200px;
              ${bp.xs} {
                top: 30px;
                max-width: 200px;
              }
              ${bp.sm} {
                top: 25px;
                max-width: 330px;
              }
              ${bp.md} {
                top: 20px;
                max-width: 440px;
              }
              mix-blend-mode: hard-light;
              // background-color: #fff;
            `}
          />
          <div
            css={css`
              position: absolute;
              left: 220px;
              top: 65px;
              font-size: 16px;
              line-height: 34px;
              padding-right: 50px;
              // min-width: 610px;
              ${bp.xs} {
                left: 220px;
                top: 55px;
                line-height: 32px;
              }
              ${bp.sm} {
                left: 350px;
                top: 65px;
                line-height: 34px;
              }
              ${bp.md} {
                left: 435px;
                top: 75px;
              }
            `}
          >
            coton [ co = cooperation + oto = 音 + ton = 音 <span css={css`vertical-align: super; font-size: 0.6rem;`}>ドイツ語</span> ]<br />
            の名のもとに、<br />
            アート・産業・研究の分野から<br />
            企業家・プランナー・研究者・アーティスト・エンジニアが集まりました。<br />
            <br />
            cotonは音・身体・生活・テクノロジーをキーワードに<br />
            来たるべき情報化社会にむけ、<br />
            最先端の研究に基づくソリューションを提案します。<br />
            <br />
            そして、アート・産業・研究がリンクする横断的領域を開拓し、<br />
            あたらしい価値を創造していきます。

          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="d-block d-sm-none">
        <div css={css`
          position: relative;
          margin: 0 auto;
          width: 265px;
        `}>
          <img
            src={logoVenture}
            alt="coton-venture-logo"
            css={css`
              margin: -20px 0 -20px 0;
              width: 265px;
            `}
          />
          <img
            src={logo}
            alt="coton-logo"
            css={css`
              margin: -20px 0 -20px 0;
              width: 265px;
            `}
          />
        </div>
        <div css={css`
          font-size: 9vw;
          font-weight: 600;
          line-height: 13vw;
          margin: 30px 0 0 20px;
        `}>
          coton<br />
          co = cooperation +<br />
          oto = 音 +<br />
          ton = 音 <span css={css`vertical-align: super; font-size: 5vw;`}>ドイツ語</span>
        </div>
        <div
          css={css`
            font-size: 16px;
            line-height: 30px;
            padding: 40px 20px;
            max-width: 600px;
            margin: 0 auto;
          `}
        >
          cotonの名のもとに、
          アート・産業・研究の分野から
          企業家・プランナー・研究者・アーティスト・エンジニアが集まりました。<br />
          <br />
          cotonは音・身体・生活・テクノロジーをキーワードに
          来たるべき情報化社会にむけ、
          最先端の研究に基づくソリューションを提案します。<br />
          <br />
          そして、アート・産業・研究がリンクする横断的領域を開拓し、
          あたらしい価値を創造していきます。

        </div>
      </div>
    </div>

  </>
);
