import React from 'react';
import {css} from '@emotion/core';

export default ({link, image, linkAttr}) => (
  <div
    css={css`
      width: 100%;
      margin: 0 0 50px 0;
      padding: 0;
      border: 1px solid #00B45A;
    `}
  >
    <a href={link} rel="noreferrer noopener nofollow" {...linkAttr}>
      <img
        src={image}
        css={css`
          width: 100%;
        `}
      ></img>
    </a>
  </div>
);